/* eslint-disable no-undef */
/* eslint-disable no-inner-declarations */
require(['jquery'], function ($) {
  if (!getCookie) {
    // eslint-disable-next-line no-unused-vars
    var _getCookie = function _getCookie(cname) {
      var name = cname + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
      }
      return '';
    };
  }
  if (getCookie('advisor-referrer-name-fr') != '') {
    var customAdvisorButton = function customAdvisorButton() {
      var persoConseillerLink = $('.perso-conseiller');
      if (persoConseillerLink.length > 0) {
        persoConseillerLink.each(function (index, element) {
          var $element = $(element);
          $element.attr('href', urlToUse);
          var persoConseillerSpan = $element.find('span');
          if (persoConseillerSpan > 0) {
            persoConseillerSpan.text(nameToUse);
          } else {
            $element.text(nameToUse);
          }
        });
      }
    };
    var PAGELANG = $('html').attr('lang').substr(0, 2);
    var nameFr = decodeURI(getCookie('advisor-referrer-name-fr'));
    var nameEn = decodeURI(getCookie('advisor-referrer-name-en'));
    var urlFr = getCookie('advisor-referrer-urlFr');
    var urlEn = getCookie('advisor-referrer-urlEn');
    var urlToUse = PAGELANG == 'fr' ? urlFr : urlEn;
    var nameToUse = PAGELANG == 'fr' ? nameFr : nameEn;
    //changment text et lien
    $('[data-utag-name=get_a_product_cta]').attr('href', urlToUse).find('p').text(nameToUse);
    customAdvisorButton();
    $(document).on('opened', '.remodal', function () {
      customAdvisorButton();
    });
  }
});